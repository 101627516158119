@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100&display=swap');

.marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    margin-top: -10;
    height: 130px;
    overflow-x: none;
    overflow-y: none;
  }
  
  .track {
    position: absolute;
    white-space: nowrap;
  }
  
  .track > h1 {
    margin: 30px 0;
    font-size: 90px;
    font-weight: normal;
    overflow-x: none;
    overflow-y: hidden;
    font-family: 'Barlow Semi Condensed', sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #f4955c;
    -webkit-text-fill-color: white;
    text-transform: uppercase;
  }